import React from "react";
import { useModal } from "../../provider/modal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { useAuth } from "../../provider/auth";

export const Modal =
  (/* { onModalClose, onUserResponse, user, useSnackbar } */) => {
    const { modal_state } = useModal();
    const { auth } = useAuth();
    const { modal_type } = modal_state;
    const modal_res = modal_type === "answer" ? true : false;

    return (
      <Dialog
        open={modal_res}
        fullScreen
        //onClose={onModalClose}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none",
          },
        }}
      >
        <Dialog
          open={modal_res}
          PaperProps={{
            style: {
              backgroundColor: "#17181a",
              boxShadow: "none",
              padding: "1rem",
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="text-white" id="alert-dialog-title">
            {"Evaluación finalizada"}
          </DialogTitle>
          <DialogActions style={{ color: "#fff" }}>
            Para ingresar a otros modulos, regresa a campus virtual
            <a
              style={{ textDecoration: "underline", marginLeft: "5px" }}
              href={`http://elearning.inatrans.cl/course/view.php?id=${auth.cookies.course_id}`}
            >
              {" "}
              aquí
            </a>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  };
