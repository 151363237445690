import React, { useEffect } from "react";
import { INATRANS } from "../../utils/state";
import vQuest from "./video.config.quest.json";
// import vClick from './video.config.click.json'
import video_default from "./video_default.json";

const getShuffledArr = (arr) => {
  const newArr = arr.slice();
  const finalArray = [];
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  /* loop para seleccionar solo 9 videos del arreglo randomizado */
  for (let j = 0; j < 9; j++) {
    finalArray.push(newArr[j]);
  }
  sessionStorage.setItem("videos", JSON.stringify(finalArray));
  // return newArr;
  return finalArray;
};

const _version = vQuest;

const _example_videos = vQuest.videos.filter(
  (v) => v.video_desc.title === "Actividad de ensayo"
);

const _rest_videos = vQuest.videos.filter(
  (v) => v.video_desc.title !== "Actividad de ensayo"
);
const _shuffle_videos = getShuffledArr(_rest_videos);

// const _shuffle_videos_position = _shuffle_videos.map((v, idx) => {
//   v.video_questions.forEach((q) => {
//     let titleSplit = q.title.split("-");
//     let newTitle = `${idx + 1} - ${titleSplit[1]}`;
//     q.title = newTitle;
//   });
//   return v;
// });

// console.log(_shuffle_videos);
// console.log(_shuffle_videos_position);

const _version_videos = [..._example_videos, ..._shuffle_videos];

const default_state = {
  loading: false,
  videos: video_default.videos,
  program_desc: video_default.desc,
  v: video_default.videos[0],
  v_desc: video_default.videos[0].video_desc,
  questions: video_default.videos[0].video_questions,
  layer: false,
};

const appInit = {
  loading: true,
  config: _version.config,
  videos: _version_videos,
  program_desc: _version.desc,
  v: _version_videos[0],
  v_desc: _version_videos[0].video_desc,
  questions: _version_videos[0].video_questions,
  layer: false,
};

/* const appClick = {
  loading: true,
  config: vClick.config,
  videos: vClick.videos,
  program_desc: vClick.desc,
  v: vClick.videos[0],
  v_desc: vClick.videos[0].video_desc,
  questions: vClick.videos[0].video_questions,
  layer: false
} */

const AppReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    /*     case 'click':
      return appClick */
    case "loading":
      return {
        ...state,
        loading: payload.loading,
      };
    case "video":
      return {
        ...state,
        v: payload.v,
        v_desc: payload.v_desc,
        questions: payload.questions,
      };
    case "layer":
      return {
        ...state,
        layer: payload.layer,
      };
    case "filter":
      return {
        ...state,
        videos: payload.videos,
        v: payload.v,
        questions: payload.questions,
      };
    case "default":
      return default_state;
    default:
      return state;
  }
};

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [app, appActions] = React.useReducer(AppReducer, appInit);
  const [countdown, setCountdown] = React.useState(null);
  const [cron, setCron] = React.useState(
    app.config === INATRANS.RISK_PREDICTION ? "00:15:00" : "00:07:00"
  );
  useEffect(() => {
    if (app.config) {
      // console.log('setting cron ')
      setCron(app.config === "quest" ? "00:15:00" : "00:07:00");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.config]);

  function createCron() {
    let countdownStart = app.config === "quest" ? 900 : 420;
    // let countdown = app.config === "quest" ? 50 : 420;
    setInterval(() => {
      countdownStart--;
      setCountdown(countdownStart);
      let hours = Math.floor(countdownStart / 3600);
      let minutes = Math.floor((countdownStart - hours * 3600) / 60);
      let seconds = Math.floor(countdownStart - hours * 3600 - minutes * 60);
      setCron(
        `${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`
      );
    }, 1000);
  }

  const value = { app, appActions, countdown, cron, createCron };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  return React.useContext(AppContext);
};
