import React, { useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import config from "./config.json";
import { useSnackbar } from "notistack";
import { useAuth } from "../../provider/auth";
import axios from "axios";
import { /* COOKIES, REACT_APP_URL, */ URL_NAMES } from "../../utils/state";
import { useApp } from "../../provider/app";
import { Loader } from "../../components/loader";
import { defineApproved } from "../../utils";
import { InatransPlayerCompleted } from "../module-completed";
import { fetchTracking } from "../../services";
import timerIcon from "../../assets/alarm.svg";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useTimer from "../../hooks/useTimer";

const url = "https://tipconsola.iapp.cl/api/tracking";

export const ModuleIIView = () => {
  const { auth, config } = useAuth();
  const [completed, setCompleted] = React.useState(false);
  const [isAware, setAware] = React.useState(false);
  const [startDate] = React.useState(new Date().toLocaleDateString());
  const [imgIndex, setImgIndex] = React.useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ]);

  useEffect(() => {
    if (auth.user.user_id /* && auth.cookies.intentos !== "test" */) {
      // track = true
      fetchTracking(config).then((data) => {
        if (data >= auth.cookies.intentos) {
          setCompleted(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Inicio logica para randomizar imagenes y respectivas respuestas */

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  React.useEffect(() => {
    console.log("order: ", localStorage.getItem("order"));
    if (!localStorage.getItem("order")) {
      let new_order = shuffleArray(imgIndex);
      localStorage.setItem("order", new_order);
    } else {
      let storageArray = localStorage.getItem("order").split(",");
      let newImgIndex = storageArray.map((item) => parseInt(item, 10));
      setImgIndex(newImgIndex);
    }
    //eslint-disable-next-line
  }, []);

  /* Fin logica para randomizar imagenes y respectivas respuestas */

  return (
    <>
      {completed ? (
        <InatransPlayerCompleted />
      ) : (
        <RiskInterceptorView imgIndex={imgIndex} startDate={startDate} />
      )}
    </>
  );
};

const RiskInterceptorView = ({ imgIndex, startDate }) => {
  const [intro, setIntro] = React.useState(true);

  return (
    <>
      {intro ? (
        <IntroRiskInterceptor setIntro={setIntro} />
      ) : (
        <RiskInterceptorViewComponent
          imgIndex={imgIndex}
          startDate={startDate}
        />
      )}
    </>
  );
};

const IntroRiskInterceptor = ({ setIntro }) => {
  return (
    <Dialog
      open={true}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "#17181a",
          boxShadow: "none",
        },
      }}
    >
      <Dialog
        open={true}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none",
            padding: "0 1rem",
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-white text-center" id="alert-dialog-title">
          {"Instrucciones Evaluación de riesgos"}
        </DialogTitle>
        <DialogContent>
          <p className="text-white text-justify">
            El siguiente test es una prueba que mide su capacidad para reconocer
            situaciones peligrosas. Usted debe observar cada cuadro y
            seleccionar UNA SITUACIÓN PELIGROSA con los números, los cuales
            pueden identificar un objeto, persona o condición climática, el cual
            puede ser causante de una situación de peligro. Recuerde solamente
            debe marcar una UNICA OPCIÓN para esto usted tiene 3 minutos que se
            cronometrarán a penas inicie el test, después de esto la respuesta
            se enviará automáticamente.
          </p>
          <p className="text-white text-justify mt-4">
            <span style={{ fontWeight: "bolder" }}>Atencion:</span>
            <br />
            Si abandonas o recargas la páginas, las preguntas ser reordenarán y
            se perderá el progreso.
          </p>
        </DialogContent>
        <DialogActions style={{ color: "#fff" }}>
          <Button onClick={() => setIntro(false)}>Comenzar</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const RiskInterceptorViewComponent = ({ imgIndex, startDate }) => {
  localStorage.getItem("order") && localStorage.removeItem("order");
  const [timer, setTimer] = React.useState(null);
  const [cron, setCron] = React.useState(
    /*  localStorage.getItem("countdown") ? localStorage.getItem("countdown") :  */ 360
  );
  /*   const imgIndex = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ]; */
  const [answers, setAnswers] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userAnswers = { ...localStorage };
  const { app, appActions } = useApp();
  const auth = useAuth();
  const questionTimer = useTimer(0)

  React.useEffect(() => {
    questionTimer.start()
  }, [answers])

  React.useEffect(() => {
    let interval = setInterval(async () => {
      let countdown = cron - 1;
      if (countdown <= 0) {
        setCron(0);
        setTimer(0);
        clearInterval(interval);
        return await handleSubmit();
      }
      setCron(countdown);
      setTimer(createCron(countdown));
      // eslint-disable-next-line
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [cron]);

  function createCron(countdown) {
    let hours = Math.floor(countdown / 3600);
    let minutes = Math.floor((countdown - hours * 3600) / 60);
    let seconds = Math.floor(countdown - hours * 3600 - minutes * 60);

    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;
  }

  const formatOutputAnswers = (data) => {
    let output = [];
    let entries = Object.entries(data);
    console.log({ entries });
    entries.forEach((element) => {
      let clean = {};
      let keys = element[0];
      let values = element[1].split(";");
      clean["key"] = keys;
      clean["value"] = JSON.parse(values[0]);
      clean["correct"] = JSON.parse(values[1]);
      clean["responseTime"] = JSON.parse(values[2]);
      output.push(clean);
    });
    console.log({ output });
    return output;
  };

  const handleSubmit = async () => {
    let output = formatOutputAnswers(userAnswers);
    let correctAnswers = output.filter((value) => value.correct).length;
    let approved = defineApproved({
      ca: correctAnswers,
      name: URL_NAMES.MODULO_II,
    });
    await axios
      .post(url, {
        client: auth.config.client,
        provider: auth.config.provider,
        course_name: auth.auth.cookies.course_name,
        course_id: auth.config.course_id,
        user_id: auth.config.user_id,
        user_name: auth.auth.cookies.user_name,
        url_name: auth.auth.cookies.url_name,
        url_cmid: auth.auth.cookies.url_cmid,
        results: JSON.stringify(answers),
        start_date: startDate,
        end_date: new Date().toLocaleDateString(),
        module_results: JSON.stringify({
          module_name: "ina1",
          totalanswers: 20,
          correct: correctAnswers,
          incorrect: 20 - correctAnswers,
          results_detail: output,
          results: approved,
          elapsedSeconds:cron <= 0 ? 360 : Math.abs(cron - 360) // NUEVO, tiempo que tardó en terminar la actividad.
        }),
      })
      .then((data) => {
        console.log(data);
        //mostrar alerta publicacion exitosa
        enqueueSnackbar(`Formulario enviado`, { variant: "success" });
        //luego mostrar la pantalla de publicacion exitosa, luego un boton para redireccionar a la proxima evaluacion
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(`Error al enviar Formulario`, { variant: "error" });
        alert(
          "ERROR AL ENVIAR EL FORMULARIO, CONTACTESE CON SERVICIO AL CLIENTE !"
        );
        setError(true);
      });
  };

  React.useEffect(() => {
    enqueueSnackbar(`Cada imagen dispone de 1 intentos`, { variant: "info" });
    for (let i = 1; i <= 20; i++) {
      localStorage.removeItem(`${i}`);
    }
    // localStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = {
    wrapper: {
      padding: "70px 70px",
      width: "100vw",
      minHeight: "100vh",
      height: "100%",
    },
    header: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      borderBottomWidth: 0.1,
      borderBottomColor: "rgb(202 208 215)",
      borderBottomStyle: "solid",
      padding: "0 0 12px 0",
    },
    headerText: {
      textTransform: "uppercase",
      color: "rgb(202 208 215)",
      fontWeight: 700,
      fontFamily: "Roboto,sans-serif",
      letterSpacing: ".02rem",
    },
    headerTextLight: {
      fontFamily: "Roboto,sans-serif",
      color: "rgb(202, 208, 215)",
      fontSize: ".75rem",
      fontWeight: 700,
      letterSpacing: ".06rem",
      lineHeight: "1rem",
      textTransform: "uppercase",
    },
    subtitle: {
      fontFamily: "Roboto,sans-serif",
      color: "rgb(202 208 215)",
      fontWeight: 700,
    },
    subtitleLight: {
      marginTop: 12,
      fontFamily: "Roboto,sans-serif",
      color: "rgb(202 208 215)",
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
    },
    completed: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      marginTop: "20%",
      padding: "1.5rem",
    },
    topCompleted: {
      color: "white",
      backgroundColor: "rgb(52, 152, 219)",
      padding: "1rem",
      boxShadow: "5px 5px 12px 5px #9d9b9b",
      borderBottom: "1px solid #1979ac",
    },
    bottomCompleted: {
      margin: "1rem",
      flexDirection: "column",
      color: "black",
      fontWeight: "500",
    },
    next: {
      backgroundColor: "rgb(52, 152, 219)",
      display: "flex",
      color: "white",
      justifyContent: "center",
      padding: "0.5rem 1.5rem",
    },
    navigateButton: {
      display: "flex",
      color: "black",
      justifyContent: "center",
      padding: "0.5rem 1.5rem",
      cursor: "pointer",
    },
  };

  React.useEffect(() => {
    if (!submitted) {
      window.onbeforeunload = function (event) {
        return "Si abandonas o recargas la páginas, las preguntas ser reordenarán y se perderá el progreso.";
      };
    } else {
      window.onbeforeunload = function (event) {
        // return "Si abandonas o recargas la páginas, las preguntas ser reordenarán y se perderá el progreso.";
      };
    }
  }, [submitted]);

  return (
    <>
      <p
        style={{
          backgroundColor: "rgb(52, 152, 219)",
          padding: ".2rem 1rem",
          borderRadius: "10px",
          position: "absolute",
          zIndex: 999999999999,
          top: 70,
          left: 40,
        }}
      >
        <img
          src={timerIcon}
          style={{ marginRight: 10, marginBottom: 3 }}
          alt="..."
        />
        {timer}
      </p>
      <div style={styles.wrapper} className="container">
        {app.loading ? (
          <Loader app={app} appActions={appActions} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            {!submitted &&
              imgIndex.map((img, idx) => {
                return (
                  <ImageComponent
                    img={img}
                    key={idx}
                    currentImg={idx}
                    answers={answers}
                    setAnswers={setAnswers}
                    correct={config.data[img - 1].correct}
                    timer={questionTimer}
                  />
                );
              })}
            {answers.length === 20 && !submitted && (
              <div style={styles.completed}>
                <h1 style={styles.topCompleted}>
                  20 preguntas completadas{" "}
                  <CheckCircleIcon
                    sx={{ color: "#6dbb6d", fontSize: "40px" }}
                  />
                </h1>
                <p style={styles.bottomCompleted}>
                  Para confirmar y enviar pulse el boton:
                </p>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Enviar
                </button>
              </div>
            )}
            {submitted && !error && (
              <div style={styles.completed}>
                <h1 style={styles.topCompleted}>Formulario Enviado</h1>
                <div className="linkNavigate" style={styles.navigateButton}>
                  Para ingresar a otros modulos, regresa a campus virtual&nbsp;
                  <a
                    style={{ textDecoration: "underline", color: "black" }}
                    href={`http://elearning.inatrans.cl/course/view.php?id=${auth.auth.cookies.course_id}`}
                  >
                    aquí
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const ImageComponent = ({
  img,
  answers,
  setAnswers,
  correct,
  currentImg,
  timer
}) => {
  const isCurrent = answers.length === currentImg ? true : false;
  const [, setFinished] = React.useState(false);
  const [, setMaxAttempts] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const [res, setRes] = React.useState(null);
  const [isCorrect, setCorrect] = React.useState(null);
  const [starProgress, setStarProgress] = React.useState(10);
  const [completed, setCompleted] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  
  let needNumber = [1, 2, 7, 9, 10, 11, 12, 13, 14, 17, 18, 19, 20];

  const styles = {
    1.1: {
      position: "absolute",
      top: "49%",
      height: "48px",
      width: "48px",
      left: "60.6%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    1.2: {
      position: "absolute",
      top: "53%",
      height: "48px",
      width: "48px",
      left: "51%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    1.3: {
      position: "absolute",
      top: "53%",
      height: "48px",
      width: "48px",
      left: "42.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    2.1: {
      position: "absolute",
      top: "49.6%",
      height: "48px",
      width: "48px",
      left: "31.6%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    2.2: {
      position: "absolute",
      top: "62.8%",
      height: "48px",
      width: "48px",
      left: "21.3%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    2.3: {
      position: "absolute",
      top: "40%",
      height: "48px",
      width: "48px",
      left: "45.7%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
    },
    3.1: {
      position: "absolute",
      top: "44.5%",
      height: "48px",
      width: "48px",
      left: "33.6%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    3.2: {
      position: "absolute",
      top: "52%",
      height: "48px",
      width: "48px",
      left: "64%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    3.3: {
      position: "absolute",
      top: "69%",
      height: "48px",
      width: "48px",
      left: "19.5%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    4.1: {
      position: "absolute",
      top: "70.3%",
      height: "48px",
      width: "48px",
      left: "77.4%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    4.2: {
      position: "absolute",
      top: "45.8%",
      height: "48px",
      width: "48px",
      left: "20%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    4.3: {
      position: "absolute",
      top: "42.4%",
      height: "48px",
      width: "48px",
      left: "60.6%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    5.1: {
      position: "absolute",
      top: "63.7%",
      height: "48px",
      width: "48px",
      left: "78%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    5.2: {
      position: "absolute",
      top: "10.9%",
      height: "48px",
      width: "48px",
      left: "347px",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    5.3: {
      position: "absolute",
      top: "42%",
      height: "48px",
      width: "48px",
      left: "43,9%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    6.1: {
      position: "absolute",
      top: "56.6%",
      height: "48px",
      width: "48px",
      left: "84.1%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    6.2: {
      position: "absolute",
      top: "38.5%",
      height: "48px",
      width: "48px",
      left: "64%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    6.3: {
      position: "absolute",
      top: "56.6%",
      height: "48px",
      width: "48px",
      left: "31.9%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    7.1: {
      position: "absolute",
      top: "76%",
      height: "48px",
      width: "48px",
      left: "57%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    7.2: {
      position: "absolute",
      top: "30%",
      height: "48px",
      width: "48px",
      left: "44.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    7.3: {
      position: "absolute",
      top: "48%",
      height: "48px",
      width: "48px",
      left: "25.6%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    8.1: {
      position: "absolute",
      top: "64.3%",
      height: "48px",
      width: "48px",
      left: "83%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    8.2: {
      position: "absolute",
      top: "54.7%",
      height: "48px",
      width: "48px",
      left: "59.6%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    8.3: {
      position: "absolute",
      top: "67.7%",
      height: "48px",
      width: "48px",
      left: "31.3%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    9.1: {
      position: "absolute",
      top: "40.5%",
      height: "48px",
      width: "48px",
      left: "74.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    9.2: {
      position: "absolute",
      top: "53.5%",
      height: "48px",
      width: "48px",
      left: "22.6%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    9.3: {
      position: "absolute",
      top: "60.8%",
      height: "48px",
      width: "48px",
      left: "34.9%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
    },
    10.1: {
      position: "absolute",
      top: "57%",
      height: "48px",
      width: "48px",
      left: "63%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    10.2: {
      position: "absolute",
      top: "46%",
      height: "48px",
      width: "48px",
      left: "43%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    10.3: {
      position: "absolute",
      top: "61%",
      height: "48px",
      width: "48px",
      left: "21%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    11.1: {
      position: "absolute",
      top: "70%",
      height: "48px",
      width: "48px",
      left: "35%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    11.2: {
      position: "absolute",
      top: "31%",
      height: "48px",
      width: "48px",
      left: "20%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    11.3: {
      position: "absolute",
      top: "47%",
      height: "48px",
      width: "48px",
      left: "41.3%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    12.1: {
      position: "absolute",
      top: "31.1%",
      height: "48px",
      width: "48px",
      left: "61.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    12.2: {
      position: "absolute",
      top: "41%",
      height: "48px",
      width: "48px",
      left: "38.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    12.3: {
      position: "absolute",
      top: "24.5%",
      height: "48px",
      width: "48px",
      left: "48%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
    },
    13.1: {
      position: "absolute",
      top: "63%",
      height: "48px",
      width: "48px",
      left: "48%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    13.2: {
      position: "absolute",
      top: "46.8%",
      height: "48px",
      width: "48px",
      left: "7.8%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    13.3: {
      position: "absolute",
      top: "34.6%",
      height: "48px",
      width: "48px",
      left: "64.2%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
    },
    14.1: {
      position: "absolute",
      top: "29.8%",
      height: "48px",
      width: "48px",
      left: "82%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    14.2: {
      position: "absolute",
      top: "57.5%",
      height: "48px",
      width: "48px",
      left: "65.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
    },
    14.3: {
      position: "absolute",
      top: "49.7%",
      height: "48px",
      width: "48px",
      left: "36.3%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
    },
    15.1: {
      position: "absolute",
      top: "53.5%",
      height: "48px",
      width: "48px",
      left: "72.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    15.2: {
      position: "absolute",
      top: "53.3%",
      height: "48px",
      width: "48px",
      left: "50.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    15.3: {
      position: "absolute",
      top: "52.5%",
      height: "48px",
      width: "48px",
      left: "31.9%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    16.1: {
      position: "absolute",
      top: "56%",
      height: "48px",
      width: "48px",
      left: "72.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    16.2: {
      position: "absolute",
      top: "59%",
      height: "48px",
      width: "48px",
      left: "6%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    16.3: {
      position: "absolute",
      top: "67%",
      height: "48px",
      width: "48px",
      left: "90%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    17.1: {
      position: "absolute",
      top: "28%",
      height: "48px",
      width: "48px",
      left: "46%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    17.2: {
      position: "absolute",
      top: "65%",
      height: "48px",
      width: "48px",
      left: "49%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    17.3: {
      position: "absolute",
      top: "25%",
      height: "48px",
      width: "48px",
      left: "27%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    18.1: {
      position: "absolute",
      top: "60%",
      height: "48px",
      width: "48px",
      left: "44%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    18.2: {
      position: "absolute",
      top: "70%",
      height: "48px",
      width: "48px",
      left: "37.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    18.3: {
      position: "absolute",
      top: "52%",
      height: "48px",
      width: "48px",
      left: "16%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    19.1: {
      position: "absolute",
      top: "40%",
      height: "48px",
      width: "48px",
      left: "34.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    19.2: {
      position: "absolute",
      top: "70%",
      height: "48px",
      width: "48px",
      left: "47%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    19.3: {
      position: "absolute",
      top: "68%",
      height: "48px",
      width: "48px",
      left: "27.5%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    20.1: {
      position: "absolute",
      top: "43%",
      height: "48px",
      width: "48px",
      left: "78%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    20.2: {
      position: "absolute",
      top: "46%",
      height: "48px",
      width: "48px",
      left: "59%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
    20.3: {
      position: "absolute",
      top: "18%",
      height: "48px",
      width: "48px",
      left: "56%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
      border: "4px solid white",
      color: "white",
    },
  };

  const textstyles = {
    light: {
      color: "#fff",
      fontFamily: "Roboto,sans-serif",
      fontWeight: 400,
    },
  };

  const containerStyles = {
    box: {
      padding: 10,
      margin: 10,
      position: "relative",
      display: isCurrent ? "unset" : "none",
      height: "100%",
      zIndex: "1",
    },
    img: {
      position: "relative",
      top: 0,
      width: "100%",
      height: "80%",
      minHeight: "80vh",
      border: "2px solid #fff",
      borderRadius: 15,
    },
    progress: {
      padding: "0 1rem",
      marginTop: "15px",
      backgroundColor: "rgb(52, 152, 219)",
      width: 100,
      height: 40,
      borderRadius: 15,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      border: "1px solid #fff",
    },
    next: {
      marginLeft: "auto",
      height: 40,
      marginTop: "15px",
      display: "flex",
      backgroundColor: "#293e4f",
      width: 100,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 15,
      cursor: "pointer",
      border: "1px solid #fff",
    },
    number1: {
      fontFamily: "Anton",
      color: "white",
      position: "absolute",
      fontSize: "30px",
      left: ".9rem",
      top: "0",
    },
    number2: {
      fontFamily: "Anton",
      color: "white",
      position: "absolute",
      fontSize: "30px",
      left: ".8rem",
      top: "0",
    },
    number3: {
      fontFamily: "Anton",
      color: "white",
      position: "absolute",
      fontSize: "30px",
      left: ".8rem",
      top: "0",
    },
  };

  const handleNext = () => {
    if (res) {
      let temp = answers.filter((r) => r.index !== img);
      let final = [...temp, res].sort((a, b) => a - b);
      timer.reset()
      setAnswers(final);
      setFinished(true);
    }
  };

  const setSelected = (idx) => {
    let isFinished = checkAttempts();
    if (isFinished) {
      return;
    }
    timer.stop()
    let keyidx = Number(idx.split(".")[1]);
    console.log(`respuesta en ms: ${timer.elapsedTime}`);
    let responseTime = timer.elapsedTime
    checkResponse(keyidx, img, responseTime);// NUEVO, RESPUESTAS CON CRONOMETRO, tiempo en que tarda el usuario en responder.
    let data = {
      index: img,
      value: keyidx,
      time: responseTime
    };
    setRes(data);
  };

  const checkResponse = (response, img, responseTime) => {
    if (response === correct) {
      setStarProgress(140);
      setCorrect(true);
      setCompleted(1);
      localStorage.setItem(img, `${response};true;${responseTime}`);
    } else {
      setCorrect(false);
      setStarProgress(10);
      setCompleted(1);
      localStorage.setItem(img, `${response};false;${responseTime}`);
    }
  };

  const checkAttempts = () => {
    if (counter >= 1) {
      setMaxAttempts(true);
      enqueueSnackbar(
        `${counter} intentos alcanzados, continue con la siguiente imagen`,
        { variant: "error" }
      );
      return true;
    } else {
      let currentCount = counter + 1;
      setCounter(currentCount);
      setMaxAttempts(false);
      return false;
    }
  };

  return (
    <div style={containerStyles.box}>
      <div style={styles[`${img}.1`]} onClick={() => setSelected(`${img}.1`)}>
        {/*         {res && res.value === 1 && (
          <>
            {isCorrect ? (
              <CheckCircleIcon
                sx={{
                  color: "#6dbb6d",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            ) : (
              <CancelIcon
                sx={{
                  color: "red",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            )}
          </>
        )} */}
        {needNumber.includes(img) && (
          <div style={containerStyles.number1}>1</div>
        )}
      </div>
      <div style={styles[`${img}.2`]} onClick={() => setSelected(`${img}.2`)}>
        {/*         {res && res.value === 2 && (
          <>
            {isCorrect ? (
              <CheckCircleIcon
                sx={{
                  color: "#6dbb6d",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            ) : (
              <CancelIcon
                sx={{
                  color: "red",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            )}
          </>
        )} */}
        {needNumber.includes(img) && (
          <div style={containerStyles.number2}>2</div>
        )}
      </div>
      <div style={styles[`${img}.3`]} onClick={() => setSelected(`${img}.3`)}>
        {/*       {res && res.value === 3 && (
          <>
            {isCorrect ? (
              <CheckCircleIcon
                sx={{
                  color: "#6dbb6d",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            ) : (
              <CancelIcon
                sx={{
                  color: "red",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            )}
          </>
        )} */}
        {needNumber.includes(img) && (
          <div style={containerStyles.number3}>3</div>
        )}
      </div>
      <img
        style={containerStyles.img}
        alt="..."
        src={`https://tipddyassets.fra1.cdn.digitaloceanspaces.com/inatrans/optimized/idet-riesgos-${img}.jpg`}
      />
      <div style={{ display: "flex" }}>
        <div style={{ ...containerStyles.progress, marginRight: 30 }}>
          <div
            style={{
              position: "relative",
              height: 15,
              width: 10,
              backgroundColor: "#5cc4f1",
              borderRadius: 10,
              transition: "0.7s ease",
            }}
          ></div>
          <StarIcon
            sx={{
              color: "#fff",
              fontSize: 40,
              marginLeft: "-15px",
              marginBottom: "0.2rem",
              zIndex: 10,
            }}
          />
          <h5 style={textstyles.light}>{completed}/1</h5>
          {/*           <h5 style={textstyles.light}>Tu respuesta: {res.value}</h5> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <p className="m-0">Opcion seleccionada : {res && res.value}</p>
        </div>
        {res && (
          <div style={containerStyles.next} onClick={handleNext}>
            <h5 style={textstyles.light}>Siguiente</h5>
          </div>
        )}
      </div>
    </div>
  );
};
