import React from "react";
/* import { useAuth } from "../../provider/auth" */
import timerIcon from "../../assets/alarm.svg";
import questions from "./data.json";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { useAuth } from "../../provider/auth";
import { useSnackbar } from "notistack";
import { /* COOKIES, REACT_APP_URL, */ URL_NAMES } from "../../utils/state";
import escalas from "./escalas.json";
import idx from "./indexes.json";
import { defineApproved } from "../../utils";
import { fetchTracking } from "../../services";
import { InatransPlayerCompleted } from "../module-completed";
import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const { escalas: scales } = escalas;
const { indexes: indexCount } = idx;
const { questions: data } = questions;

const styles = {
  wrapper: {
    padding: "80px 70px",
    width: "100vw",
    minHeight: "65vh",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    borderBottomWidth: 0.1,
    borderBottomColor: "rgb(202 208 215)",
    borderBottomStyle: "solid",
    padding: "0 0 12px 0",
  },
  headerText: {
    textTransform: "uppercase",
    color: "rgb(202 208 215)",
    fontWeight: 500,
  },
  topQuestion: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    backgroundColor: "rgb(52, 152, 219)",
    padding: "1rem",
    boxShadow: "5px 5px 12px 5px #9d9b9b",
    borderBottom: "1px solid #1979ac",
  },
  bottomQuestion: {
    display: "flex",
    margin: "1rem",
    flexDirection: "column",
  },
  container: {
    margin: "0",
    padding: ".5rem 0",
    verticalAlign: "center",
  },
  containerTop: {
    color: "rgb(52, 152, 219)",
    margin: "0",
    padding: ".5rem 0",
    verticalAlign: "center",
  },
  question: {
    backgroundColor: "white",
    padding: "1.5rem",
  },
  next: {
    backgroundColor: "rgb(52, 152, 219)",
    display: "flex",
    color: "white",
    justifyContent: "center",
    padding: "0.5rem 1.5rem",
  },
  content: {
    color: "black",
    justifyContent: "center",
    display: "flex",
    fontWeight: "400",
  },
  navigateButton: {
    display: "flex",
    color: "black",
    justifyContent: "center",
    padding: "0.5rem 1.5rem",
    cursor: "pointer",
  },
};

export const ModuleIIIView = () => {
  const { auth, config } = useAuth();
  const [completed, setCompleted] = React.useState(false);
  const [startDate] = React.useState(new Date().toLocaleDateString());
  useEffect(() => {
    if (auth.user.user_id /* && auth.cookies.intentos !== "test" */) {
      // track = true
      fetchTracking(config).then((data) => {
        if (data >= auth.cookies.intentos) {
          setCompleted(data);
        }
      });
    }
    // setIsTracked(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {completed ? (
        <InatransPlayerCompleted />
      ) : (
        <PsychoQuizView startDate={startDate} />
      )}
    </>
  );
};

const PsychoQuizView = ({ startDate }) => {
  const [intro, setIntro] = React.useState(true);

  return (
    <>
      {intro ? (
        <IntroModal setIntro={setIntro} />
      ) : (
        <PsychoQuizViewComponent startDate={startDate} />
      )}
    </>
  );
};

const IntroModal = ({ setIntro }) => {
  return (
    <Dialog
      open={true}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "#17181a",
          boxShadow: "none",
        },
      }}
    >
      <Dialog
        open={true}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none",
            padding: "0 1rem",
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-white text-center" id="alert-dialog-title">
          {"Instrucciones evaluación psicológica"}
        </DialogTitle>
        <DialogContent>
          <p className="text-white text-justify">
            En esta evaluación usted deberá escoger una sola alternativa por
            pregunta. Al estar contestando recuerde estos puntos: No medite
            mucho tiempo las respuestas. Responda lo primero que se le venga a
            la mente, de modo natural. Trate de dar siempre la mejor respuesta a
            un ritmo de alrededor de 5 preguntas por minuto, haciéndolo así,
            terminará en 20 o 25 minutos. Recuerde que la evaluación tiene una
            duración de 25 min, al finalizar el tiempo la evaluación se enviará
            de forma automática
          </p>
        </DialogContent>
        <DialogActions style={{ color: "#fff" }}>
          <Button onClick={() => setIntro(false)}>Comenzar</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const PsychoQuizViewComponent = ({ startDate }) => {
  const [timer, setTimer] = React.useState(null);
  const [cron, setCron] = React.useState(
    /*  localStorage.getItem("countdown") ? localStorage.getItem("countdown") :  */ 1500
  );
  const [answers, setAnswers] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  React.useEffect(() => {
    let interval = setInterval(async () => {
      let countdown = cron - 1;
      if (countdown <= 0) {
        setCron(0);
        setTimer(0);
        clearInterval(interval);
        window.location.reload();
      }
      setCron(countdown);
      setTimer(createCron(countdown));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [cron]);

  function createCron(countdown) {
    let hours = Math.floor(countdown / 3600);
    let minutes = Math.floor((countdown - hours * 3600) / 60);
    let seconds = Math.floor(countdown - hours * 3600 - minutes * 60);

    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;
  }

  //obtener indexes
  //obtener los valores de las respuestas de cada index
  //sumar las respuestas si coinciden con isNegative
  //ir a buscar el numero de la suma en su escala correspondiente
  const evaluate = (answers) => {
    let { extraversion, psicoticismo, neuroticismo, escala_ele } = indexCount;

    let ext = 0;
    let psi = 0;
    let neu = 0;
    let ele = 0;

    extraversion.forEach((e, idx) => {
      // console.log(data[e - 1].isNegative, idx);
      if (data[e - 1].isNegative === (answers[e - 1].value === 2)) {
        ext++;
      }
    });

    psicoticismo.forEach((e, idx) => {
      // console.log(data[e - 1].isNegative, idx);
      if (data[e - 1].isNegative === (answers[e - 1].value === 2)) {
        psi++;
      }
    });

    neuroticismo.forEach((e, idx) => {
      console.log(data[e - 1].isNegative, idx);
      if (data[e - 1].isNegative === (answers[e - 1].value === 2)) {
        neu++;
      }
    });

    escala_ele.forEach((e, idx) => {
      // console.log(data[e - 1].isNegative, idx);
      if (data[e - 1].isNegative === (answers[e - 1].value === 2)) {
        ele++;
      }
    });

    // console.log(ext, psi, neu, ele);

    let extRes = scales.extraversion[ext].value;
    let psiRes = scales.psicoticismo[psi].value;
    let neuRes = scales.neuroticismo[neu].value;
    let eleRes = scales.escala_ele[ele].value;

    let extDesc = [
      "Reservada, discreta, socialmente inhibida",
      extRes,
      "Sociable, activa, animada",
    ];
    let neuDesc = [
      "Relajada, poco preocupada y estable",
      neuRes,
      "Aprensiva, ansiosa y con humor deprimido",
    ];
    let psiDesc = [
      "Altruista, Empatica y convencional",
      psiRes,
      "Impulsiva, creativa y poco socializada",
    ];
    let eleDesc = [
      "Sincera, inconformista",
      eleRes,
      "Poco sincera, conformista",
    ];

    let descriptions = {
      extDesc,
      neuDesc,
      psiDesc,
      eleDesc,
    };

    let results = [
      {
        title: "Autocontrol",
        key: 0,
        value: psiRes > 60 ? "No apto" : "Apto",
      },
      {
        title: "Estabilidad Emocional",
        key: 1,
        value: neuRes > 60 ? "No apto" : "Apto",
      },
      {
        title: "Adecuación a la norma",
        key: 2,
        value: eleRes < 30 ? "No apto" : "Apto",
      },
      {
        title: "Relaciones interpersonales",
        key: 3,
        value: extRes < 30 ? "No apto" : "Apto",
      },
      {
        title: "Tolerancia y adaptación",
        key: 4,
        value: psiRes < 30 ? "No apto" : "Apto",
      },
    ];
    return { results, descriptions };
  };

  const handleSubmit = async () => {
    let { results, descriptions } = evaluate(answers);
    console.log({ results });
    console.log({ descriptions });
    let ca = results.filter((indicator) => indicator.value === "Apto").length;
    let approved = defineApproved({ ca, name: URL_NAMES.MODULO_III });
    console.log({ approved });
    await axios
      .post("https://tipconsola.iapp.cl/api/tracking", {
        client: auth.config.client,
        provider: auth.config.provider,
        course_name: auth.auth.cookies.course_name,
        course_id: auth.config.course_id,
        user_id: auth.config.user_id,
        user_name: auth.auth.cookies.user_name,
        url_name: auth.auth.cookies.url_name,
        url_cmid: auth.auth.cookies.url_cmid,
        video_id: "",
        start_date: startDate,
        end_date: new Date().toLocaleDateString(),
        results: JSON.stringify(answers),
        module_results: JSON.stringify({
          module_name: "ina2",
          totalanswers: answers.length,
          correct: "",
          incorrect: "",
          results_detail: results,
          results: approved,
          description_range: descriptions,
        }),
      })
      .then((data) => {
        console.log(data);
        //mostrar alerta publicacion exitosa
        enqueueSnackbar(`Formulario enviado`, { variant: "success" });
        //luego mostrar la pantalla de publicacion exitosa, luego un boton para redireccionar a la proxima evaluacion
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(`Error al enviar Formulario`, { variant: "error" });
        alert(
          "ERROR AL ENVIAR EL FORMULARIO, CONTACTESE CON SERVICIO AL CLIENTE !"
        );
        setError(true);
      });
  };

  return (
    <>
      <p
        style={{
          backgroundColor: "rgb(52, 152, 219)",
          padding: ".2rem 1rem",
          borderRadius: "10px",
          position: "absolute",
          zIndex: 999999999999,
          top: 70,
          left: 40,
        }}
      >
        <img
          src={timerIcon}
          style={{ marginRight: 10, marginBottom: 3 }}
          alt="..."
        />
        {timer}
      </p>
      <div style={styles.wrapper} className="container">
        {answers.length < 83 && (
          <div style={styles.header}>
            <h6 style={styles.headerText}>Evaluación</h6>
          </div>
        )}
        {answers.length === 83 && !submitted && !error && (
          <div style={styles.question}>
            <h1 style={styles.topQuestion}>
              83 preguntas completadas{" "}
              <CheckCircleIcon sx={{ color: "#6dbb6d", fontSize: "40px" }} />
            </h1>
            <p className="my-3" style={styles.content}>
              Para confirmar y enviar pulse el boton:
            </p>
            <button
              className="btn mx-auto mb-0"
              style={styles.next}
              onClick={handleSubmit}
            >
              Enviar
            </button>
          </div>
        )}
        {submitted && !error && (
          <div style={styles.question}>
            <h1 style={styles.topQuestion}>Formulario Enviado</h1>
            <div className="linkNavigate" style={styles.navigateButton}>
              Para ingresar a otros modulos, regresa a campus virtual&nbsp;
              <a
                style={{ textDecoration: "underline", color: "black" }}
                href={`http://elearning.inatrans.cl/course/view.php?id=${auth.auth.cookies.course_id}`}
              >
                aquí
              </a>
            </div>
          </div>
        )}

        {questions.questions.map((q, index) => {
          return (
            <QuestionComponent
              description={q.description}
              index={index}
              key={index}
              answers={answers}
              setAnswers={setAnswers}
            />
          );
        })}
      </div>
    </>
  );
};

const QuestionComponent = ({ description, index, answers, setAnswers }) => {
  const isCurrent = index === answers.length ? true : false;
  const [check, setCheck] = React.useState({
    a: false,
    b: false,
  });

  /*   const [checkedA, setCheckedA] = React.useState(false);
  const [checkedB, setCheckedB] = React.useState(false); */

  // const handleA = () => {
  //   if(checkedA) ?setCheckedA(true) :setCheckedA(false)
  // }

  const handleClick = (selected) => {
    setCheck({
      a: selected === "a" ? true : false,
      b: selected === "b" ? true : false,
    });
  };

  const handleNext = () => {
    let data = {
      idx: index + 1,
      value: check.a ? 1 : 2,
    };
    let filtered = answers.filter((a) => a.idx !== index + 1);
    setAnswers([...filtered, data]);
  };

  const containerstyles = {
    question: {
      display: isCurrent ? "" : "none",
      backgroundColor: "white",
      padding: "1.5rem",
    },
  };

  return (
    <div className="pregunta my-auto" style={containerstyles.question}>
      <section className="top" style={styles.topQuestion}>
        <h2>Pregunta {index + 1}</h2>
      </section>
      <section className="mb-0" style={styles.bottomQuestion}>
        <h4 style={styles.containerTop}>{description}</h4>
        <div style={{ display: "flex" }}>
          <input
            type="checkbox"
            checked={check.a}
            onClick={() => handleClick("a")}
          />
          <label>&nbsp;a. Si</label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            type="checkbox"
            checked={check.b}
            onClick={() => handleClick("b")}
          />
          <label>&nbsp;b. No</label>
        </div>
        {/* <button className='btn mx-auto mt-3 mb-0' style={styles.next} onClick={handleNext}>Siguiente</button> */}
        {/* para fines de prueba, puede descomentar el boton de arriba para evitar seleccionar respuestas y llegar al final del modulo*/}
        {check.a && (
          <button
            className="btn mx-auto mt-3 mb-0"
            style={styles.next}
            onClick={handleNext}
          >
            Siguiente
          </button>
        )}
        {check.b && (
          <button
            className="btn mx-auto mt-3 mb-0"
            style={styles.next}
            onClick={handleNext}
          >
            Siguiente
          </button>
        )}
      </section>
    </div>
  );
};
