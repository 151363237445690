import React, { useEffect, useState } from "react";
import { useConfig } from "../../provider/config";
import { useApp } from "../../provider/app";
import { useModal } from "../../provider/modal";
import YTPlayer from "yt-player";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ModalPlayer } from "./ModalPlayer";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuth } from "../../provider/auth";
import axios from "axios";
import { defineApproved } from "../../utils";
import { URL_NAMES } from "../../utils/state";

let player = null;
let url = "https://tipconsola.iapp.cl/api/tracking";
let headers = {
  "Content-Type": "application/json",
};

export const VideoPlayer = ({ startDate }) => {
  const { modal_state, modal_dispatch } = useModal();
  const [playing, setPlaying] = React.useState(true);
  const { app, appActions, createCron, countdown } = useApp();
  const { configActions } = useConfig();
  const { auth } = useAuth();
  const stepper_idx = app.videos.findIndex((v) => v === app.v) - 2;
  const steps = app.videos.filter(
    (v) => v.video_desc.title !== "Actividad de ensayo"
  );
  const isMobile = window.innerWidth < 870;
  const userAnswers = { ...localStorage };
  const [posted, setPosted] = useState(false);

  useEffect(() => {
    if (player) {
      player.destroy();
      player = new YTPlayer("#player", {
        controls: 0,
      });
      player.load(app.v.video_id);
      configActions.setupVideo(player);
    } else {
      player = new YTPlayer("#player", {
        controls: 0,
      });
      player.load(app.v.video_id);
      configActions.setupVideo(player);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.v]);

  useEffect(() => {
    // console.log(countdown);
    if (countdown && countdown < 1 && !posted) {
      // console.log(countdown);
      postResult()
        .then(() => {
          setPosted(true);
          /*  window.location.replace(`http://elearning.inatrans.cl/`); */
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  useEffect(() => {
    modal_state.modal ? setPlaying(false) : setPlaying(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal_state.modal]);

  const formatOutputAnswers = (data) => {
    let output = [];
    let entries = Object.entries(data);
    entries.forEach((element) => {
      let clean = {};
      let keys = element[0].split(" - ");
      let values = element[1].split(";");
      // console.log("formatOutput keys y values", keys, values);
      clean["key"] = keys[1];
      clean["rank"] = keys[0];
      clean["value"] = JSON.parse(values[0]);
      clean["correct"] = JSON.parse(values[1]);
      output.push(clean);
    });
    console.log("output final:", output);
    return output;
  };

  const formatAllQuestions = (a) => {
    let new_array = [];
    for (let i = 0; i < a.length; i++) {
      let q = a[i].video_questions;
      for (let j = 0; j < q.length; j++) {
        new_array.push({
          title: q[j].title,
          answer: "No se respondió",
          value: "no es correcto",
        });
      }
    }
    return new_array;
  };

  async function postResult() {
    if (countdown && countdown < 1) {
      let videos_array = JSON.parse(sessionStorage.getItem("videos"));
      let all_questions = formatAllQuestions(videos_array);
      let output = formatOutputAnswers({ ...localStorage });
      for (let n = output.length; n < all_questions.length; n++) {
        // onUserResponse(
        //   all_questions[n].answer,
        //   all_questions[n].title,
        //   all_questions[n].value,
        //   "box"
        // );
        localStorage.setItem(
          `${all_questions[n].title}`,
          `${JSON.stringify(all_questions[n].answer)};${false}`
        );
        console.log(n);
      }
    }
    try {
      let output = formatOutputAnswers({ ...localStorage });
      let correctAnswers = output.filter((value) => value.correct).length;
      let approved = defineApproved({
        ca: correctAnswers,
        name: URL_NAMES.MODULO_I,
      });
      console.log({ output });
      console.log({ largoo: output.length });
      console.log({ correctAnswers });
      const { data } = await axios.post(
        url,
        {
          client: auth.cookies.config,
          provider: "youtube",
          course_name: auth.cookies.course_name,
          course_id: auth.cookies.course_id,
          user_id: auth.cookies.user_id,
          user_name: auth.cookies.user_name,
          url_name: auth.cookies.url_name,
          url_cmid: auth.cookies.url_cmid,
          start_date: startDate,
          end_date: new Date().toLocaleDateString(),
          results: JSON.stringify(localStorage),
          module_results: JSON.stringify({
            module_name: "ina0",
            totalanswers: output.length,
            correct: correctAnswers,
            incorrect: output.length - correctAnswers,
            results_detail: output,
            results: approved,
          }),
        },
        headers
      );
      console.log({ data });
      modal_dispatch({
        type: "answer",
        payload: {
          modal_title: "Tus respuestas son:",
          modal_type: "answer",
          user_answers: { ...localStorage },
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  /* Evento de configuracion ejecutado al finalizar la reproduccion de un video */
  useEffect(() => {
    player.on("ended", () => {
      let isExampleCompleted = app.videos.findIndex((v) => v === app.v) === 1;
      if (isExampleCompleted) {
        return handleClickOpen();
      }
      let v_idx = app.videos.findIndex((v) => v === app.v) + 1;
      let v_index = v_idx === app.videos.length ? 0 : v_idx;
      if (v_idx === app.videos.length) {
        appActions({
          type: "default",
        });
        postResult();
        // modal_dispatch({
        //   type: 'answer',
        //   payload: {
        //     modal_title: 'Tus respuestas son:',
        //     modal_type: 'answer',
        //     user_answers: {...localStorage}
        //   }
        // })
      } else {
        appActions({
          type: "loading",
          payload: { loading: true },
        });
        appActions({
          type: "video",
          payload: {
            v: app.videos[v_index],
            v_desc: app.videos[v_index].video_desc,
            questions: app.videos[v_index].video_questions,
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  const handleClick = () => {
    player.play();
  };

  const handleStart = () => {
    localStorage.clear();
    const idx = app.videos.findIndex((v) => v === app.v) + 1;
    console.log(idx);
    appActions({
      type: "loading",
      payload: { loading: true },
    });
    setTimeout(() => {
      appActions({
        type: "video",
        payload: {
          v: app.videos[idx],
          v_desc: app.videos[idx].video_desc,
          questions: app.videos[idx].video_questions,
        },
      });
      createCron()
    }, 1000);
  };

  const layer_styles = {
    background: playing ? "transparent" : "#fff",
    opacity: "0.9",
    display: "flex",
    flexWrap: "wrap",
  };

  const StepperLoader = () => {
    return (
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "0",
          left: "0",
          marginBottom: "40px",
        }}
      >
        {!isMobile && (
          <Stepper
            activeStep={stepper_idx}
            alternativeLabel
            sx={{ color: "#fff" }}
          >
            {steps.map((video, idx) => (
              <Step key={idx}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
    );
  };

  const [open, setOpen] = React.useState(false);
  const [openTrial, setOpenTrial] = React.useState(false);
/* 
  useEffect(() => {
    openTrial && createCron()
  }, [openTrial]) */


  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {
        <div
          onClick={handleClick}
          className="layer_css"
          style={layer_styles}
          id="layer"
        ></div>
      }
      <div style={{ marginTop: 30 }} id="player"></div>
      {app.v_desc.title === "Actividad Completada" ? "" : <StepperLoader />}
      <ModalPlayer player={player} setOpenTrial={setOpenTrial} createCron={createCron} />
      <Dialog
        open={open}
        fullScreen
        //onClose={onModalClose}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none",
          },
        }}
      >
        <Dialog
          open={open}
          PaperProps={{
            style: {
              backgroundColor: "#17181a",
              boxShadow: "none",
            },
          }}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="text-white" id="alert-dialog-title">
            {"Ejemplos finalizados"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className="text-white"
              id="alert-dialog-description"
            >
              Estimado alumno, se finalizaron los ejercicios de ejemplo, a
              continuación deberá contestar la evaluación de predicción de
              riesgos
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleStart}>Comenzar</Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <Dialog
        open={openTrial}
        fullScreen
        //onClose={onModalClose}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none",
          },
        }}
      >
        <Dialog
          open={openTrial}
          PaperProps={{
            style: {
              backgroundColor: "#17181a",
              boxShadow: "none",
            },
          }}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="text-white" id="alert-dialog-title">
            {"Comienzo de ensayo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className="text-white"
              id="alert-dialog-description"
            >
              Estimado alumno, comienzan las actividades de ensayo
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="mx-auto"
              onClick={() => {
                setOpenTrial(false);
              }}
            >
              Comenzar
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    </>
  );
};
