import React from 'react';

const useTimer = (initialState = 0) => {
  const [elapsedTime, setElapsedTime] = React.useState(initialState);
  const [isRunning, setIsRunning] = React.useState(false);
  const countRef = React.useRef(null);

  const intervalRef = React.useRef(null);

  const start = () => {
    if (!isRunning) {
      console.log('start timer')
      const startTime = Date.now() - elapsedTime;
      intervalRef.current = setInterval(() => {
        setElapsedTime(Date.now() - startTime);
      }, 1);
      setIsRunning(true);
    }
  };

  const stop = () => {
    console.log('pause timer')
    clearInterval(intervalRef.current);
    setIsRunning(false);
  };

  const reset = () => {
    console.log('reset timer')
    clearInterval(intervalRef.current);
    setIsRunning(false);
    setElapsedTime(0);
  };


  return { elapsedTime, isRunning, start, stop, reset };
}

export default useTimer;