import { IconButton } from '@mui/material'

import { Link } from 'react-router-dom'
import { useApp } from '../../provider/app';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { useEffect } from 'react';



export const LinksView = ({auth, cron}) => {
  const { app } = useApp()

  useEffect(() => {
    console.log(cron)
  }, [cron])
  

  //const innerMobile = window.innerWidth < 450
  const anchor_css = {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '1rem',
    textDecoration: 'none',
  }

  return(
  <>
    <IconButton aria-label="">
      <Link to="#" style={anchor_css}>
        {app.v_desc.title === 'Actividad Completada' ? 
        (
          <>
            <AlarmOnIcon/>
            <p style={{ margin: '0 0.2rem'}} >Completado</p>
          </>
        ) : (
          <>
            <AccessAlarmIcon/>
            <p style={{ margin: '0 0.2rem'}} >{ cron }</p>
          </>
        )}
      </Link>
    </IconButton>  
 {/*    <IconButton aria-label="">
      <Link to="/" style={anchor_css}>
        <CameraIndoorIcon/>
        {!innerMobile && <p className='mx-1'>Home</p> }
      </Link>
    </IconButton>  
    <IconButton aria-label="">
      <Link to={`${auth.config === 'moodle' ? '/protected' : '/player'}`} style={anchor_css}>
        <VideoLibraryIcon/>
        {!innerMobile ? auth.config === 'moodle' ? <p className='mx-1'> Consola</p> : <p className='mx-1'>Player</p> : ''}       
      </Link>
    </IconButton> */}
  </>
  )
}