export const INATRANS = {
  RISK_INTERCEPTOR: "inatrans-identificacion-de-riesgos",
  RISK_PREDICTION: "inatrans-prediccion-de-riesgos",
  PSYCHOLOGY_QUIZ: "inatrans-evaluacion-psicologica",
  CERTIFICATE: "certificate",
  PDF: "pdf",
};

export const REACT_APP_URL = {
  PROD: "https://tipconsola.tipddy.cl/",
  DEV: "https://tipconsoladev.tipddy.cl/",
  LOCAL: "http://localhost:3000/",
};

export const URL_NAMES = {
  MODULO_I: "Prediccion de riesgo",
  MODULO_II: "Identificacion de riesgo",
  MODULO_III: "Evaluacion psicologica",
  CERTIFICATE: "CERTIFICATE",
  PDF: "PDF",
};

export const COOKIES = {
  MODULO_I:
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1313131113&url_name=quest&config=inatrans&intentos=6&brand=inatrans",
  MODULO_II:
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1414141413&url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=test",
  MODULO_III:
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=15151515&url_name=inatrans-evaluacion-psicologica&config=inatrans&intentos=test",
  CERTIFICATE:
    "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=16161616&url_name=certificate&config=inatrans",
  PDF: "?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=171717&url_name=pdf&config=inatrans",
};

// MODULO_I : ?course_id=935&course_name=beta&user_id=1111&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=11113&url_name=quest&config=inatrans&intentos=2
// MODULO_II : ?course_id=935&course_name=local&user_id=2211&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=26005&url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=2
// MODULO_III : ?course_id=935&course_name=beta&user_id=1111&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=11115&url_name=inatrans-evaluacion-psicologica&config=inatrans&intentos=2
// CERTIFICATE : ?course_id=935&course_name=beta&user_id=1111&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=11116&url_name=certificate&config=inatrans
// PDF : ?course_id=12&course_name=beta&user_id=1111&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=11116&url_name=pdf&config=inatrans

//?config=admin&course_id=935&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl
