import React from "react";
import axios from "axios";
import brandingJSON from "../../branding/branding.config.json";
import { useApp } from "../app";
import { Cookies } from "./cookies";
import { InatransNavigation } from "./navigator";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const { app, appActions } = useApp();
  const [config, setConfig] = React.useState({
    client: "inatrans",
    provider: "youtube",
    course_id: "",
    user_id: "",
    url_name: "",
    url_cmid: "",
    video_id: "",
    attempts: "",
  });

  const [auth, setAuth] = React.useState({
    user: "",
    enabled: false,
    cookies: "",
    config: "",
  });

  const trackingURL = "https://tipconsola.iapp.cl/api/exist/tracking";
  const headers = {
    "Content-Type": "application/json",
  };

  const authActions = {
    isCompleted: async function () {
      try {
        const { data } = await axios.post(
          trackingURL,
          {
            client: config.client,
            provider: config.provider,
            course_id: config.course_id,
            user_id: config.user_id,
            url_cmid: config.url_cmid,
          },
          { headers }
        );
        console.log(data);
        if (data.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getTracking: async function (callback) {
      this.tracking = [];
      if (config.user_id) {
        for (const video of app.videos) {
          await axios
            .post("https://tipconsola.iapp.cl/api/tracking/show", {
              client: config.client,
              provider: config.provider,
              course_id: config.course_id,
              user_id: config.user_id,
              url_name: config.url_name,
              url_cmid: config.url_cmid,
              video_id: video.video_id,
              attempts: video.attempts,
            })
            .then((res) => {
              if (res.data === "yes") {
                this.tracking.push(video);
              }
              console.log(res);
            })
            .catch((e) => console.log(e));
        }
        return this.filterActivity(this.tracking, callback);
      }
    },
    filterActivity: function (tracking, callback) {
      this.tracking = tracking;
      if (this.tracking.length > 0) {
        appActions({
          type: "filter",
          payload: {
            videos: this.tracking,
            v: this.tracking[0],
            questions: this.tracking[0].video_questions,
          },
        });
      } else {
        appActions({
          type: "default",
        });
      }
      callback();
    },
    setCookie: function () {
      console.log("set");
      this.session = {};
      this.search = document.URL.split("?")[1];
      this.search_params = this.search.split("&");
      this.search_params.forEach((param) => {
        let key = param.split("=")[0];
        let value = param.split("=")[1];
        this.session[key] = value;
      });
      document.cookie = `config=${JSON.stringify(
        this.session
      )}; samesite=none; secure`;
      return window.location.replace(`/`);
    },
    getCookies: function (name) {
      this.cookies = "";
      this.user = "";
      const value = `; ${document.cookie}` || "";
      const parts = value.split(`; ${name}=`) || "";
      if (parts && parts.length === 2) {
        this.cookies = parts.pop().split(";").shift();
        this.cookies = JSON.parse(this.cookies);
        let adapter = new Cookies(this);
        adapter.setCookiesConfig();
      }
    },
    inatrans: function (cookies) {
      let name = this.cookies["user_name"].split("%20").join(" ");
      this.cookies = cookies;
      this.user = {
        user_name: name,
        user_id: this.cookies["user_id"],
        user_email: this.cookies["user_email"],
      };
      setAuth({
        ...auth,
        cookies: this.cookies,
        user: this.user,
        config: this.cookies.config,
      });
      setConfig({
        ...config,
        course_id: this.cookies["course_id"],
        user_id: this.cookies["user_id"],
        url_name: this.cookies["url_name"],
        url_cmid: this.cookies["url_cmid"],
        video_id: app.v.video_id,
        attempts: app.v.attempts,
      });
      let navigator = new InatransNavigation(this.cookies);
      navigator.navigate();
    },
    iframe: function (cookies) {
      this.cookies = cookies;
      this.user = { user_name: this.cookies["user_name"] };
      setAuth({
        ...auth,
        cookies: this.cookies,
        user: this.user,
        config: this.cookies.config,
      });
      if (window.location.pathname !== "/player") {
        window.location.replace("/player");
      }
    },
    inatransAdmin: function (cookies) {
      this.cookies = cookies;
      this.user = {
        user_name: this.cookies["user_name"],
        user_id: this.cookies["user_id"],
      };
      setAuth({
        ...auth,
        cookies: this.cookies,
        user: this.user,
        config: this.cookies.config,
      });
      if (window.location.pathname !== "/admin") {
        window.location.replace("/admin");
      }
      setConfig({
        ...config,
        course_id: this.cookies["course_id"],
        user_id: this.cookies["user_id"],
        url_name: this.cookies["url_name"],
        url_cmid: this.cookies["url_cmid"],
        course_name: this.cookies["course_name"],
      });
    },
    Branding: function () {
      this.title = document.getElementById("title");
      this.favicon = document.getElementById("favicon");
      this.meta_description = document.getElementById("meta_description");
      return {
        title: this.title,
        favicon: this.favicon,
        meta: this.meta_description,
      };
    },
    setBranding: function (config) {
      const { title, favicon, meta } = this.Branding();
      favicon.href = `${brandingJSON[`${config}`].favicon}`;
      title.textContent = `${brandingJSON[`${config}`].title}`;
      meta.content = `${brandingJSON[`${config}`].meta_description}`;
    },
  };

  const value = { auth, authActions, setAuth, config, setConfig };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
