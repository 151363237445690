import { Player } from "../components/player";
import { Unauthorized } from "../views/401";
import { Admin } from "../views/admin";
import { Certificate } from "../views/certificate/v2";
import PDF from "../views/PDF";
import { Console } from "../views/console";
import { Home } from "../views/home/index.dev";
import { ModuleIIIView } from "../views/psycho-quiz";
import { ModuleIIView } from "../views/risk-interceptor";

export const routes = [
  {
    path: "/",
    element: Home,
    isPrivate: false,
  },
  {
    path: "/401",
    element: Unauthorized,
    isPrivate: false,
  },
  {
    path: "/admin",
    element: Admin,
    isPrivate: true,
  },
  {
    path: "/player",
    element: Player,
    isPrivate: true,
  },
  {
    path: "/inatrans-identificacion-de-riesgos",
    element: ModuleIIView,
    isPrivate: true,
  },
  {
    path: "/inatrans-evaluacion-psicologica",
    element: ModuleIIIView,
    isPrivate: true,
  },
  {
    path: "/protected",
    element: Console,
    isPrivate: true,
  },
  {
    path: "/certificate",
    element: Certificate,
    isPrivate: true,
  },
  {
    path: "/pdf",
    element: PDF,
    isPrivate: true,
  },
];
