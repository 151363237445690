import React, { useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useModal } from '../../provider/modal'
import Dialog from '@mui/material/Dialog'
import { Quest } from './Quest'
import { createPortal } from 'react-dom'


export const ModalPlayer = ({player, setOpenTrial, createCron}) => {
  const { modal_state  } = useModal()
  const [open, setOpen] = React.useState(true)
  const { modal } = modal_state

  React.useEffect(() => {
    setTimeout(() => {
      setOpen(false)
    }, 500)
    return () => setOpen(true)
  }, [modal])

  

    return createPortal(
      <Dialog
        open={modal}
        fullScreen
        //onClose={onModalClose}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none"
          },
        }}>
      { open ? (<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>) : (<Quest player={player} setOpenTrial={setOpenTrial}/>)}
    </Dialog>,
  document.getElementById('modal-body')         
  )
}